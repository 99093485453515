import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import CtaBanner from "../../components/cta-banner";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";

const ManagementCoachingLondonPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "17.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/daniel-brookbank.jpg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/andrew-durling.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Management Coaching London";
	const description =
		"New to management, want to revise your style or looking to update your skills? Management Coaching London can help you be an effective leader.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: `${siteUrl}`,
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Management Coaching London",
				item: `${siteUrl}/management-coaching-london`,
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/management-coaching-london`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Management Coaching London",
						},
					],
				}}
			/>
			<Hero
				isDarken={true}
				backgroundImage={heroImage}
				title="Management Coaching London"
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Do you want to take your management to the next level?
							</h2>
							<p>
								New to management, want to revise your style or looking to
								update your skills? Management Coaching can help you be an
								effective leader.
							</p>

							<p>
								You don’t have to do it on your own. Management coaching can
								help you develop your management style and skills, give you
								objective, constructive feedback, help you set and achieve
								goals, and support you to make tough decisions and have
								difficult conversations.
							</p>
							<p>
								You need to choose a management coach who knows you, has a good
								grasp of the industry and the challenges you face.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Is a management coach the answer?
							</h3>
							<p>
								As a coach with over 35 years experience, I’ve worked with lots
								of managers in London, who struggle with their management style
								and leadership decisions and want to excel at their role while
								maintaining their work-life balance.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								What does your future look like?
							</h3>

							<p>
								You’re looking for a management coach because you want to make
								changes. What would those changes look like?
							</p>
							<ul className="pl-4">
								<li>Overflowing with self-confidence?</li>
								<li>
									Developed or strengthened your leadership and management
									skills?
								</li>
								<li>
									Able to communicate with your staff and team effectively?
								</li>
								<li>
									Boosted staff morale and created a working environment you’re
									all proud of?
								</li>
								<li>
									Improved your management skills, increased productivity and
									profitability?
								</li>
								<li>
									Became a more effective leader, able to make decisions and be
									a shining example to others?
								</li>
							</ul>

							<p>
								It’s time to shake off the things that are holding you back and
								step into this new version of you and management coaching can
								help you do just that.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Ready for a discovery call?"
				btnTxt="Book now"
				btnLink="/contact-us"
				bgColor="light"
			/>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">How are you feeling right now?</h2>
							<ul className="pl-4">
								<li>
									Is it lonely at the top and you don’t want anyone to know you
									need help and support?
								</li>
								<li>
									Are you struggling with the big decisions or do those
									uncomfortable choices keep you awake at night?
								</li>
								<li>
									Are you suffering with imposter syndrome; waiting for someone
									to question you and your position?
								</li>
								<li>Do you avoid the difficult conversations?</li>
								<li>Does your management style need an overhaul?</li>
								<li>
									Are you team unmotivated or unhappy and you want to shake
									things up?
								</li>
								<li>
									Worried you have blind spots and you’re missing things,
									constantly second guessing yourself?
								</li>
								<li>
									Do you feel stuck and unsure about how to move forward?{" "}
								</li>
							</ul>
							<p>
								A management coach can help you overcome these challenges and
								help to shape you into a manager who is able to successfully
								lead their team. All you need to do is decide you’re ready to
								step in this new direction with my management coaching.{" "}
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book your discovery call
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								How does management coaching work?
							</h2>
							<p>
								As a London-based management coach, it’s my job to help you
								support managers and leaders like you. Sessions can be
								remote/virtual or face to face that can include leadership and
								business coaching as well professional and personal development
								it that helps you towards your long term goals
							</p>
							<p>
								I will help you understand your big goals, plan out a way to
								make these happen and hold you accountable through the process.{" "}
							</p>

							<p>
								Unlike other coaches, I don’t use a pre-pared coaching process,
								a one-size fits all approach or tell you what you should do. I
								tailor-make your sessions for you, to give you and your
								organisation the exact help you’re looking for, enabling you to
								thrive in your management role and build a positive future for
								you and your team by building a coaching relationship to support
								you.{" "}
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/1.jpg"
							/>
							<h2 className="text-primary pb-3">
								Why do right questions matter?
							</h2>

							<p>
								In my role as a management coach, I need to ask you the right
								questions and really listen to what you have to say. It’s more
								than just the words that you speak, it’s about the sentiment
								behind those words and your thought process.
							</p>
							<p>
								I use my counselling and psychotherapy training to REALLY listen
								to my clients in a non-judgemental way getting the to the very
								heart of your words. Together, I can help you uncover false
								assumptions, fears and life-limiting beliefs as these are what
								cause the lack of confidence, self-doubt, imposter syndrome etc.
								that could be standing in your way.
							</p>
							<p>
								Remember, all my coaching is confidential, so have you a space
								place to open up and a sounding board to bounce ideas off.
							</p>
							<p>
								Management coaching is a way for you to understand your thoughts
								and how they affect your management style and confidence in the
								role.
							</p>
							<p>
								It creates a can do attitude that you can bring to work and your
								personal life and management coaching can make it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-tertiary text-white py-5 ">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">Why trust me?</h2>
							<p>
								Having been self employed my whole life, I’ve seen it all and
								experienced highs as well as lows. I combine my business
								qualifications with counselling and psychotherapy training along
								with nearly four decades in the commercial world to help you
								align your thoughts with your goals, so you can get to where you
								want to be in your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<CtaBanner
				headline="Ready to work with me? Click here to book a discovery call"
				btnTxt="Book now"
				btnLink="/contact-us"
				bgColor="light"
			/>

			<section className="bg-secondary text-white py-5 ">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Ready to work with me?</h3>
							<p>
								There is no magic fix, no blueprint to success or a secret
								ingredient. You have to put in the work and I can help you do
								that.{" "}
							</p>
							<p>
								But, unless you do something differently, you will keep getting
								the same outcome.{" "}
							</p>
							<p>
								Are you ready to make that change and watch the positive impact
								it has on your life?
							</p>
							<p>
								Book your discovery call with me today. What have you got to
								lose?
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default ManagementCoachingLondonPage;
